import { SharedLayoutComponent } from "@puntaje/platforms/udla"
import { SimpleSharedLayoutComponent } from "@puntaje/platforms/udla"
import {
    UsuariosEditComponent,
    UsuarioPerfilComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompletarRegistroComponent,
    IncompleteRegisterComponent,
    RegisterConfirmationComponent,
    RegisterComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

//import { LoginComponent }  from './login/login.component';
//import { UsuariosComponent }  from './usuarios.component';
//import { UsuariosNewComponent }  from './usuarios.new.component';

//import { UsuariosDeleteComponent }  from './usuarios.delete.component';
//import { RegisterComponent } from './register/register.component';
//import { EdicionComponent } from './edicion/edicion.component';

export const routes: Routes = [
    //{ path: 'usuarios', component: UsuariosComponent, canActivate: [TokenGuard, AuthGuard] },
    /*{ path: 'usuarios/login', component: LoginComponent },*/
    {
        path: "usuarios/register",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent }]
    },
    {
        path: "usuarios/registro",
        component: SharedLayoutComponent,
        children: [{ path: "", component: RegisterComponent }]
    },
    //{ path: 'usuarios/new', component: UsuariosNewComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "usuarios/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "usuarios/editar",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "usuarios/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: UsuarioPerfilComponent, canActivate: [TokenGuard, AuthGuard], data: { tab: "" } }
        ]
    },
    {
        path: "usuarios/passwords/reset/:reset_password_token",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ResetPasswordComponent }]
    },
    {
        path: "usuarios/passwords/forgot",
        component: SimpleSharedLayoutComponent,
        children: [{ path: "", component: ForgotPasswordComponent }]
    },
    /*{ path: 'usuarios/:id/logros', component: UsuarioComponent, canActivate: [TokenGuard, AuthGuard], data: {tab: 'logros'} },*/
    //{ path: 'usuarios/:id/edit', component: UsuariosEditComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/edicion', component: EdicionComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: 'usuarios/:id/delete', component: UsuariosDeleteComponent, canActivate: [TokenGuard, AuthGuard] }
    {
        path: "usuarios/:id/completar_registro",
        component: SharedLayoutComponent,
        children: [{ path: "", component: CompletarRegistroComponent }]
    },
    { path: "usuarios/confirmar_email/:token", component: RegisterConfirmationComponent },
    {
        path: "pending_info",
        component: SharedLayoutComponent,
        children: [{ path: "", component: IncompleteRegisterComponent }]
    }
]

export const routing = RouterModule.forChild(routes)
