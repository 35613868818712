import { Component, OnInit, Input } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "udla-landing",
    templateUrl: "udla-landing.component.html",
    styleUrls: ["udla-landing.component.scss"]
})
export class UdlaLandingComponent implements OnInit {
    @Input() enableRegister = false
    config: typeof config
    landingImage = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    logoExtraUdla1 = "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/udla/mobile/Componentes-Banner_2.png"
    logoUdlaMobile = "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/udla/mobile/LogoLetraBlanca.png"
    logoUdlaFooter = "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/udla/mobile/LogoPreUDLA.png"
    logoUdlaFooterExtra =
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/udla/mobile/AcreditacionBlancoBorde2.png"
    logo: string
    logoFooter: string
    titulo: string
    logosExtra: any[]
    isMobile = false

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }

        this.landingImage = this.config.app.baseStyle?.landing?.backgroundImage
            ? this.config.app.baseStyle?.landing?.backgroundImage
            : "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"

        this.titulo = this.config.app.baseStyle?.landing?.subtitle ? this.config.app.baseStyle?.landing?.subtitle : ""

        this.logo = this.config.app.baseStyle?.landing?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logo]
            : this.config.app.assets.logoLargeLight

        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight

        this.logosExtra = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []

        this.resizeElements()
    }

    resizeElements() {
        const backgroundImage = this.config.app.baseStyle?.landing?.backgroundImage
        const backgroundImageMobile = this.config.app.baseStyle?.landing?.backgroundImageMobile

        const windowWidth = window.innerWidth
        if (windowWidth < 768) {
            this.landingImage = backgroundImageMobile || backgroundImage
            this.isMobile = true
        } else {
            this.landingImage = backgroundImage
            this.isMobile = false
        }
    }
}
