import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        /*this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.menuItems = [
                {
                    route: "/saber/generar_simulacro/" + asignaturasByEvaluacionTipo["saber"][0].id,
                    params: { tipo_instrumento: "saber" },
                    label: "Generar Simulacro Saber 3/5/9",
                    text: "Genera simulacros saber y compártelos con tus estudiantes. Con opciones personalizables.",
                    linkText: "Comenzar",
                    icon: "hacer-prueba"
                },
                {
                    route: "/saber11/generar_simulacro/" + asignaturasByEvaluacionTipo["saber11"][0].id,
                    params: { tipo_instrumento: "saber11" },
                    label: "Generar Simulacro Saber 11",
                    text: "Genera simulacros Saber 11 y compártelos con tus estudiantes. Con opciones personalizables.",
                    linkText: "Comenzar",
                    icon: "hacer-prueba"
                },
                {
                    route: "/saber/estadisticas/",
                    params: { tipo_instrumento: "saber", asignatura_id: asignaturasByEvaluacionTipo["saber"][0].id },
                    label: "Estadísticas Simulacros Saber 3/5/9",
                    text:
                        "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en los simulacros.",
                    linkText: "Ver estadísticas",
                    icon: "estadistica-o"
                },
                {
                    route: "/saber11/estadisticas/",
                    params: {
                        tipo_instrumento: "saber11",
                        asignatura_id: asignaturasByEvaluacionTipo["saber11"][0].id
                    },
                    label: "Estadísticas Simulacros Saber 11",
                    text:
                        "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en los simulacros.",
                    linkText: "Ver estadísticas",
                    icon: "estadistica-o"
                }
            ]
        })*/
    }
}
