import { Component } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular.
Implementa una sección de contenido y un título.

shared-layout-titulo
shared-layout-contenido

#################################*/

@Component({
    templateUrl: "shared_layout.component.html",
    selector: "shared-layout",
    styleUrls: ["shared_layout.component.scss"]
})
export class SharedLayoutComponent {}
