<div class="contenedor" (window:resize)="resizeElements()">
    <!-- Login -->
    <div id="section1">
        <div class="caja caja-inicio">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="caja-inner-margin">
                <ng-container *ngIf="isMobile">
                    <div class="caja-logos">
                        <img src="{{ logoUdlaMobile }}" class="logo-udla" />
                    </div>
                </ng-container>
                <div class="titulo">
                    <h1>
                        <b>PREPARA LA PAES</b>
                    </h1>
                    <p>Inscríbete aquí en nuestro</p>
                    <p>Preuniversitario online gratuito</p>
                </div>
                <div class="login-container">
                    <login-standard
                        class="login"
                        [enableContactUs]="false"
                        [enableRegister]="enableRegister"
                        [enableFacebookLogin]="false"
                        [enablePasswordRecovery]="true"
                    ></login-standard>
                </div>
                <!-- <br /> -->
                <div class="footer-udla">
                    <ng-container *ngIf="isMobile">
                        <img src="{{ logoUdlaFooter }}" class="footer-logo-mobile" />
                        <img src="{{ logoUdlaFooterExtra }} " class="footer-logo-mobile-extra" />
                        <div class="contact-us">
                            <p><b>Contáctanos:</b></p>
                            <p>
                                Si no puedes acceder escríbenos a
                                <b>contactoudla@ogr.cl</b>
                            </p>
                        </div>
                        <div class="franja-footer"></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <simple-modal></simple-modal>
</div>
